import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={540}
      height={390}
      viewBox="0 0 540 390"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={382.15} cy={19.556} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={382.15} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={382.15} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={382.15} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={382.15} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={382.15} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={382.15} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={19.555} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={404.875} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={19.555} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={427.6} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={19.555} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={19.555} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={450.325} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={495.775} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={19.555} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={19.555} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={65.018} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={42.287} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={87.75} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={133.213} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={473.05} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={110.481} rx={2.005} ry={2.006} fill="#8493FF" />
      <ellipse cx={518.501} cy={155.944} rx={2.005} ry={2.006} fill="#8493FF" />
      <rect x={38.989} width={415.235} height={270.734} rx={3} fill="#E6E9FE" />
      {/* Grid */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27.293 21.425h107.22v266.839H27.293a5.846 5.846 0 01-5.849-5.844V27.268a5.846 5.846 0 015.849-5.843zm0 268.786c-4.307 0-7.798-3.488-7.798-7.791V27.268c0-4.303 3.49-7.79 7.797-7.79h399.639c4.307 0 7.798 3.487 7.798 7.79V282.42c0 4.303-3.491 7.791-7.797 7.791H27.292zm109.169-1.947h47.106v-46.746h-47.106v46.746zm0-48.694h47.106v-46.745h-47.106v46.745zm0-48.693h47.106v-46.745h-47.106v46.745zm0-48.693h47.106V95.439h-47.106v46.745zm0-48.693h47.106V46.745h-47.106v46.746zm0-48.694H432.78V27.268a5.846 5.846 0 00-5.849-5.843H136.462v23.372zm49.068 48.694V46.745h49.055v46.746H185.53zm51.017 0V46.745h47.093v46.746h-47.093zm49.055 0V46.745h47.093v46.746h-47.093zm49.055 0V46.745h49.055v46.746h-49.055zm51.017 0V46.745h47.106v46.746h-47.106zm-149.127 1.948v46.745h47.093V95.439h-47.093zm49.055 46.745h47.093V95.439h-47.093v46.745zm49.055 48.693v-46.745h49.055v46.745h-49.055zm98.123 0h-47.106v-46.745h47.106v46.745zm-49.068 50.641h-49.055v46.746h49.055v-46.746zm1.962-1.948h47.106v-46.745h-47.106v46.745zm0 48.694h41.258a5.846 5.846 0 005.848-5.844v-40.902h-47.106v46.746zm-1.962-48.694v-46.745h-49.055v46.745h49.055zm49.068-97.386V95.439h-47.106v46.745h47.106zm-49.068 0V95.439h-49.055v46.745h49.055zm-51.017 99.334h-47.093v46.746h47.093v-46.746zm-49.055 0h-47.093v46.746h47.093v-46.746zm0-1.948h-47.093v-46.745h47.093v46.745zm49.055 0v-46.745h-47.093v46.745h47.093zm-96.148-48.693h47.093v-46.745h-47.093v46.745zm96.148-46.745v46.745h-47.093v-46.745h47.093zm-98.11 97.386H185.53v46.746h49.055v-46.746zm0-1.948v-46.745H185.53v46.745h49.055zm0-95.438v46.745H185.53v-46.745h49.055zm0-1.948V95.439H185.53v46.745h49.055z"
        fill="url(#web-development-svg-prefix__paint0_linear)"
      />
      <rect
        y={38.955}
        width={116.968}
        height={270.734}
        rx={3}
        fill="url(#web-development-svg-prefix__paint1_linear)"
      />
      <ellipse
        opacity={0.7}
        cx={58.484}
        cy={97.386}
        rx={27.292}
        ry={27.268}
        fill="#3F52DB"
      />
      <rect
        opacity={0.7}
        x={50.686}
        y={157.766}
        width={46.787}
        height={5.843}
        rx={2.922}
        fill="#3F52DB"
      />
      <rect
        opacity={0.7}
        x={19.495}
        y={157.766}
        width={19.495}
        height={5.843}
        rx={2.922}
        fill="#3F52DB"
      />
      <rect
        opacity={0.7}
        x={50.686}
        y={185.034}
        width={46.787}
        height={5.843}
        rx={2.922}
        fill="#3F52DB"
      />
      <rect
        opacity={0.7}
        x={19.495}
        y={185.034}
        width={19.495}
        height={5.843}
        rx={2.922}
        fill="#3F52DB"
      />
      <rect
        opacity={0.7}
        x={50.686}
        y={212.302}
        width={46.787}
        height={5.843}
        rx={2.922}
        fill="#3F52DB"
      />
      <rect
        opacity={0.7}
        x={19.495}
        y={212.302}
        width={19.495}
        height={5.843}
        rx={2.922}
        fill="#3F52DB"
      />
      {/* Windows */}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M142.311 85.7a5.846 5.846 0 00-5.849 5.843v66.223a5.846 5.846 0 005.849 5.843h107.22a5.845 5.845 0 005.848-5.843V91.543a5.845 5.845 0 00-5.848-5.843h-107.22zm140.361 0a5.846 5.846 0 00-5.849 5.843v66.223a5.846 5.846 0 005.849 5.843h107.22a5.845 5.845 0 005.848-5.843V91.543a5.845 5.845 0 00-5.848-5.843h-107.22zm-5.849 103.23a5.846 5.846 0 015.849-5.843h107.22a5.845 5.845 0 015.848 5.843v66.223a5.845 5.845 0 01-5.848 5.843h-107.22a5.846 5.846 0 01-5.849-5.843V188.93zm-134.512-5.843a5.846 5.846 0 00-5.849 5.843v19.477a5.846 5.846 0 005.849 5.843h40.938a5.846 5.846 0 005.849-5.843V188.93a5.846 5.846 0 00-5.849-5.843h-40.938zm62.382 5.843a5.846 5.846 0 015.849-5.843h40.938a5.846 5.846 0 015.849 5.843v19.477a5.846 5.846 0 01-5.849 5.843h-40.938a5.846 5.846 0 01-5.849-5.843V188.93zm0 46.745a5.846 5.846 0 015.849-5.843h40.938a5.846 5.846 0 015.849 5.843v19.478a5.846 5.846 0 01-5.849 5.843h-40.938a5.846 5.846 0 01-5.849-5.843v-19.478zm-68.231 0a5.846 5.846 0 015.849-5.843h40.938a5.846 5.846 0 015.849 5.843v19.478a5.846 5.846 0 01-5.849 5.843h-40.938a5.846 5.846 0 01-5.849-5.843v-19.478z"
        fill="url(#web-development-svg-prefix__paint2_linear)"
      />
      {/* Graph */}
      <path
        d="M152.074 136.774c-.226-8.477 1.763-25.754 11.526-27.052 12.203-1.623 4.067 17.313 14.237 19.477 10.169 2.164 4.067-12.985 12.203-14.067 8.135-1.082 3.39 17.313 11.525 18.395 8.135 1.083 3.39-30.298 15.593-30.298s7.457 38.955 19.66 38.955c12.204 0 0-27.052 8.814-27.052"
        stroke="#fff"
        strokeLinecap="round"
      />
      <rect
        x={292.419}
        y={99.334}
        width={91.625}
        height={1.947}
        rx={0.974}
        fill="#fff"
      />
      <rect
        x={292.419}
        y={114.916}
        width={91.625}
        height={1.947}
        rx={0.974}
        fill="#fff"
      />
      <rect
        x={292.419}
        y={130.498}
        width={91.625}
        height={1.947}
        rx={0.974}
        fill="#fff"
      />
      <rect
        x={292.419}
        y={146.08}
        width={52.635}
        height={1.947}
        rx={0.974}
        fill="#fff"
      />
      <path
        opacity={0.7}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M335.307 201.59c-11.305 0-20.469 9.157-20.469 20.451 0 11.295 9.164 20.452 20.469 20.452 11.305 0 20.469-9.157 20.469-20.452 0-11.294-9.164-20.451-20.469-20.451zm-27.292 20.451c0-15.059 12.219-27.268 27.292-27.268 15.073 0 27.292 12.209 27.292 27.268 0 15.06-12.219 27.269-27.292 27.269-15.073 0-27.292-12.209-27.292-27.269z"
        fill="#3F52DB"
      />
      <path
        d="M407.074 390c26.573 0 48.115-5.79 48.115-12.933s-21.542-12.933-48.115-12.933c-26.574 0-48.116 5.79-48.116 12.933S380.5 390 407.074 390z"
        fill="#E6E9FE"
      />
      <path
        d="M451.325 371.525c21.864 0 39.589-4.357 39.589-9.732 0-5.375-17.725-9.732-39.589-9.732-21.864 0-39.589 4.357-39.589 9.732 0 5.375 17.725 9.732 39.589 9.732z"
        fill="#E6E9FE"
      />
      <path
        d="M433.76 144.827h-2.535v2.982h2.535v-2.982zM433.76 151.505h-2.535v2.981h2.535v-2.981zM433.76 158.185h-2.535v2.982h2.535v-2.982zM433.76 164.865h-2.535v2.982h2.535v-2.982zM433.76 171.544h-2.535v2.981h2.535v-2.981zM433.76 178.224h-2.535v2.981h2.535v-2.981zM433.76 191.584h-2.535v2.981h2.535v-2.981z"
        fill="#006DFE"
      />
      <path
        d="M448.086 301.541v22.155h7.309v-19.94a10.992 10.992 0 00-7.309-2.215z"
        fill="#BBC3FF"
      />
      <path
        opacity={0.34}
        d="M446.388 305.725s6.305 1.106 9.912 7.81c3.607 6.703 6.595-.786 5.9-3.853-.696-3.066-.332-6.203-2.189-8.557-1.856-2.353-5.402-5.705-10.242-4.421-4.84 1.285-5.475.571-5.204 3.458.27 2.888.73 5.991.73 5.991"
        fill="#000"
      />
      <path
        d="M425.118 364.767c3.122 0 5.653-2.793 5.653-6.239 0-3.446-2.531-6.24-5.653-6.24-3.122 0-5.653 2.794-5.653 6.24s2.531 6.239 5.653 6.239zM457.736 364.136c0 3.446-2.53 6.239-5.651 6.239s-5.653-2.793-5.653-6.239c0-3.446 2.531-6.241 5.653-6.241 3.123 0 5.651 2.793 5.651 6.241zM484.424 358.528c0 3.448-2.53 6.241-5.653 6.241-3.122 0-5.651-2.793-5.651-6.241 0-3.448 2.531-6.24 5.651-6.24 3.121 0 5.653 2.793 5.653 6.24z"
        fill="#191E56"
      />
      <path
        d="M446.746 323.587s4.932-2.009 10.169 0l.142 7.058 25.664 21.15v5.025h-7.577l-.102-3.864-19.649-15.344.121 19.206-.974 4.511h-5.115l-.944-4.466-.214-18.715-19.548 14.65-.081 3.752-7.023.044v-5.314l25.17-20.545v-4.229l-.039-2.919zM465.044 192.664s8.347-2.959 20.768 0c12.42 2.959 19.84 15.317 20.251 25.306.412 9.989-4.07 71.388-20.509 86.91-16.438 15.523-57.922 7.878-66.4 4.642-8.479-3.236-14.74-15.988-14.74-15.988l42.496-30.197 2.261-64.798 15.873-5.875z"
        fill="#4C62FF"
      />
      <path
        d="M437.345 189.496l-1.012-.08s-10.358.309-13.605 4.325c-3.247 4.017-5.475 22.861-5.475 22.861l6.416 48.27s3.866 6.797 23.5 6.797 27.364-8.032 27.364-8.032l-1.825-22.602s3.287-5.124 3.828-10.609c.541-5.486-4.87-34.755-10.745-39.002-5.875-4.247-18.861-2.405-18.861-2.405l-9.585.477z"
        fill="#FFAB00"
      />
      <path
        d="M459.995 198.61s-2.706 3.166-1.701 8.951 5.256 13.903 3.632 16.991c-1.624 3.088-4.74 8.239-4.07 9.449.67 1.21 2.034 1.88 2.034 1.88l8.865 5.609s2.343.645 3.954-.46l1.096.308-26.048 14.029-2.987-4.323s9.471-10.233 10.32-13.709c.85-3.477.232-3.73 2.165-7.195 1.933-3.464 3.864-5.704 2.628-9.526-1.236-3.823-3.517-10.041-3.054-13.709.463-3.669.73-5.94 3.166-8.295zM425.673 199.054s-1.63 6.823.093 15.16l6.163 29.838 1.061-.544s-7.317-36.698-7.317-44.454z"
        fill="#FE8E00"
      />
      <path
        d="M324.437 387.721v-123.51a.88.88 0 00-.881-.88h-2.413a.88.88 0 00-.881.88v123.51c0 .486.395.88.881.88h2.413a.88.88 0 00.881-.88zM375.75 372.25l-7.62-109.981a.831.831 0 00-.888-.772l-2.503.173a.832.832 0 00-.773.887l7.62 109.981c.032.458.43.804.888.773l2.503-.173a.833.833 0 00.773-.888z"
        fill="#191E56"
      />
      <path
        d="M402.155 356.331s-.618 4.973-.605 8.971c.013 3.999 2.732 7.18 1.344 10.931-1.389 3.751-12.814 3.864-20.952 4.829-8.138.965-15.339-.781-14.297-6.888 1.042-6.107 14.254-3.703 19.434-11.295 5.18-7.592 3.623-8.832 3.623-8.832l11.453 2.284zM445.755 360.177s-.618 4.973-.605 8.973c.013 4 2.734 7.18 1.345 10.929-1.388 3.75-12.816 3.866-20.946 4.829-8.13.963-15.339-.781-14.295-6.886 1.043-6.105 14.252-3.705 19.433-11.297 5.182-7.592 3.622-8.83 3.622-8.83l11.446 2.282z"
        fill="#FFAB00"
      />
      <path
        d="M424.849 264l-1.186.875s-35.3 11.38-38.599 22.088c-3.298 10.709 1.958 72.136 1.958 72.136s9.587 7.567 18.085-.773l.695-57.384 35.672-13.206 10.313.31s12.369-8.806 13.14-9.424c.772-.618 10.514-7.567 10.514-7.567l-6.029-9.887L424.849 264z"
        fill="#1E2889"
      />
      <path
        d="M449.938 269.663s-18.66 15.914-20.23 27.648c-1.569 11.735 0 63.489 0 63.489s12.036 8.032 20.23 0c0 0-3.401-47.266 3.864-55.298 7.266-8.031 20.099-15.292 21.645-27.186 1.547-11.894-.927-14.674-.927-14.674l-12.397-2.007-12.185 8.028z"
        fill="#1E2889"
      />
      <path
        d="M494.918 387.721v-123.51a.88.88 0 00-.881-.88h-2.412a.88.88 0 00-.881.88v123.51c0 .486.394.88.881.88h2.412a.88.88 0 00.881-.88zM539.607 372.25l-7.62-109.981a.832.832 0 00-.888-.772l-2.503.173a.832.832 0 00-.773.887l7.62 109.981a.833.833 0 00.888.773l2.503-.173a.833.833 0 00.773-.888z"
        fill="#191E56"
      />
      <path
        d="M537.789 259.16H313.717c-.996 0-1.803.806-1.803 1.802v4.737c0 .995.807 1.802 1.803 1.802h224.072c.996 0 1.803-.807 1.803-1.802v-4.737c0-.996-.807-1.802-1.803-1.802z"
        fill="#191E56"
      />
      <path d="M538.565 239.465h-54.187v6.564h54.187v-6.564z" fill="#BBC3FF" />
      <path d="M540 246.029h-54.188v6.565H540v-6.565z" fill="#97A4FF" />
      <path d="M537.371 252.595h-54.187v6.565h54.187v-6.565z" fill="#7989F8" />
      <path
        d="M436.042 179.071s1.689 3.549.29 10.346c0 0 1.082 3.315 4.87 3.242 3.788-.073 5.721-3.647 5.721-3.647s-1.804-2.897-1.057-9.051l.051-2.29-4.791-3.244-5.084 4.644z"
        fill="#FFACA6"
      />
      <path
        d="M425.157 160.407s-9.839-.348-4.325-20.17c0 0 5.047 3.797 14.013 4.31 8.966.512 18.964.308 20.201 16.27.515 10.297-9.173 19.152-9.173 19.152l-8.764-5.559-11.952-14.003z"
        fill="#1E2889"
      />
      <path
        d="M424.745 157.883s-2.68 22.45 5.566 23.743c8.246 1.293 13.708-8.134 13.708-8.134s4.123-.516 3.71-4.892c-.413-4.376-3.65-2.729-3.65-2.729s-3.659-1.596-3.607-5.458c0 0-4.432 1.029-6.443-3.037 0 0-3.35 2.321-6.751-1.237-.008-.006-.884 2.114-2.533 1.744zM459.894 235.888s-8.462 8.905-14.9 11.171c-6.437 2.266-9.526-.025-12.103 5.187l-.516 1.094s9.276 2.984 15.976-.309l20.407-11.534-8.864-5.609z"
        fill="#FFACA6"
      />
      <path d="M460.303 253.598h-76.326v5.562h76.326v-5.562z" fill="#97A4FF" />
      <path
        d="M440.03 259.16h-66.479l-9.616-46.545h66.478l9.617 46.545z"
        fill="#BBC3FF"
      />
      <path
        d="M435.053 259.16h-66.479l-9.616-46.545h66.478l9.617 46.545z"
        fill="#97A4FF"
      />
      <path
        d="M395.328 238.805c3.421-.485 5.762-3.926 5.228-7.686-.533-3.76-3.739-6.415-7.16-5.93-3.422.485-5.763 3.925-5.229 7.685.534 3.76 3.74 6.415 7.161 5.931z"
        fill="#fff"
      />
      <defs>
        <linearGradient
          id="web-development-svg-prefix__paint0_linear"
          x1={227.112}
          y1={19.477}
          x2={227.112}
          y2={290.211}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8493FF" />
          <stop offset={1} stopColor="#BEC6FF" />
        </linearGradient>
        <linearGradient
          id="web-development-svg-prefix__paint1_linear"
          x1={58.484}
          y1={38.955}
          x2={58.453}
          y2={309.689}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B61FF" />
          <stop offset={1} stopColor="#8896FF" />
        </linearGradient>
        <linearGradient
          id="web-development-svg-prefix__paint2_linear"
          x1={266.101}
          y1={85.7}
          x2={266.095}
          y2={260.996}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#4B61FF" />
          <stop offset={1} stopColor="#8896FF" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
