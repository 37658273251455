import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={540}
      height={390}
      viewBox="0 0 540 390"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={111.175} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={111.175} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={111.175} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={111.175} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={111.175} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={111.175} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={111.175} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={133.9} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={156.625} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={179.35} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={224.8} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={21.506} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={66.969} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={44.237} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={89.7} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={135.163} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={202.075} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={112.431} rx={2.005} ry={2.006} fill="#28D6AD" />
      <ellipse cx={247.525} cy={157.894} rx={2.005} ry={2.006} fill="#28D6AD" />
      <path
        d="M191.047 0c-10.766 0-19.495 8.73-19.495 19.5v284.7c0 10.769 8.729 19.5 19.495 19.5h155.957c10.766 0 19.494-8.731 19.494-19.5V19.5c0-10.77-8.728-19.5-19.494-19.5h-35.091a3.899 3.899 0 00-3.898 3.9v1.95a7.8 7.8 0 01-7.798 7.8h-62.383a7.799 7.799 0 01-7.798-7.8V3.9a3.9 3.9 0 00-3.899-3.9h-35.09z"
        fill="#CFF9EF"
      />
      <mask
        id="mobile-development-svg-prefix__a"
        maskUnits="userSpaceOnUse"
        x={171}
        y={0}
        width={196}
        height={324}
      >
        <path
          d="M191.047 0c-10.766 0-19.495 8.73-19.495 19.5v284.7c0 10.769 8.729 19.5 19.495 19.5h155.957c10.766 0 19.494-8.731 19.494-19.5V19.5c0-10.77-8.728-19.5-19.494-19.5h-35.091a3.899 3.899 0 00-3.898 3.9v1.95a7.8 7.8 0 01-7.798 7.8h-62.383a7.799 7.799 0 01-7.798-7.8V3.9a3.9 3.9 0 00-3.899-3.9h-35.09z"
          fill="#EBFFFA"
        />
      </mask>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.834 29.25c-9.69 0-17.545 7.857-17.545 17.55v9.75h191.047V46.8c0-9.693-7.855-17.55-17.545-17.55H358.7a1.95 1.95 0 00-1.949 1.95v1.95c0 5.385-4.364 9.75-9.747 9.75h-62.383c-5.383 0-9.747-4.365-9.747-9.75V31.2a1.95 1.95 0 00-1.95-1.95h-35.09zM413.285 331.5V46.8c0-3.051-.7-5.938-1.949-8.51a19.581 19.581 0 00-9.038-9.04 19.411 19.411 0 00-8.507-1.95H358.7a3.897 3.897 0 00-3.899 3.9v1.95c0 4.308-3.491 7.8-7.797 7.8h-62.383a7.799 7.799 0 01-7.798-7.8V31.2a3.9 3.9 0 00-3.899-3.9h-35.09c-3.05 0-5.937.7-8.508 1.95a19.574 19.574 0 00-9.037 9.04 19.426 19.426 0 00-1.95 8.51v284.7c0 3.051.701 5.938 1.95 8.51a19.573 19.573 0 009.037 9.04 19.418 19.418 0 008.508 1.95h155.957c3.05 0 5.936-.701 8.507-1.95a19.58 19.58 0 009.038-9.04 19.426 19.426 0 001.949-8.51zm-48.736-29.25v46.8h-46.787v-46.8h46.787zm46.787-1.95h-44.838v-46.8h44.838v46.8zm0 1.95v29.25c0 9.693-7.855 17.55-17.545 17.55h-27.293v-46.8h44.838zm0-50.7v-46.8h-44.838v46.8h44.838zm-46.787 0v-46.8h-46.787v46.8h46.787zm0 48.75v-46.8h-46.787v46.8h46.787zm46.787-97.5h-44.838V156h44.838v46.8zm0-48.75v-46.8h-44.838v46.8h44.838zm-46.787 0v-46.8h-46.787v46.8h46.787zm0 48.75V156h-46.787v46.8h46.787zm46.787-97.5h-44.838V58.5h44.838v46.8zm-46.787 0V58.5h-46.787v46.8h46.787zm-48.737-46.8v46.8h-46.787V58.5h46.787zm0 243.75v46.8h-46.787v-46.8h46.787zm0-50.7v-46.8h-46.787v46.8h46.787zm0 48.75v-46.8h-46.787v46.8h46.787zm0-146.25v-46.8h-46.787v46.8h46.787zm0 48.75V156h-46.787v46.8h46.787zM267.076 58.5v46.8h-46.787V58.5h46.787zm0 243.75v46.8h-29.242c-9.69 0-17.545-7.857-17.545-17.55v-29.25h46.787zm0-50.7v-46.8h-46.787v46.8h46.787zm0 48.75v-46.8h-46.787v46.8h46.787zm0-146.25v-46.8h-46.787v46.8h46.787zm0 48.75V156h-46.787v46.8h46.787zm-30.217-156a2.924 2.924 0 100-5.849 2.924 2.924 0 000 5.849zm18.52-2.925a2.924 2.924 0 11-5.849.001 2.924 2.924 0 015.849-.001z"
        fill="url(#mobile-development-svg-prefix__paint0_linear)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M251.48 64.35a7.8 7.8 0 00-7.798 7.8v68.25a7.8 7.8 0 007.798 7.8h163.755a7.8 7.8 0 007.798-7.8V72.15a7.8 7.8 0 00-7.798-7.8H251.48zM290.469 156a7.798 7.798 0 00-7.797 7.8v27.3c0 4.308 3.491 7.8 7.797 7.8h163.755a7.799 7.799 0 007.798-7.8v-27.3c0-4.308-3.491-7.8-7.798-7.8H290.469zm31.192 58.5c0-4.308 3.491-7.8 7.798-7.8h163.754a7.799 7.799 0 017.798 7.8v27.3c0 4.308-3.491 7.8-7.798 7.8H329.459a7.799 7.799 0 01-7.798-7.8v-27.3zm46.787 42.9a7.799 7.799 0 00-7.798 7.8v27.3c0 4.308 3.491 7.8 7.798 7.8h163.754a7.799 7.799 0 007.798-7.8v-27.3c0-4.308-3.491-7.8-7.798-7.8H368.448z"
        fill="url(#mobile-development-svg-prefix__paint1_linear)"
      />
      <ellipse
        opacity={0.7}
        cx={306.065}
        cy={177.45}
        rx={13.646}
        ry={13.65}
        fill="#20BB96"
      />
      <rect
        x={327.509}
        y={163.8}
        width={35.09}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <rect
        x={327.509}
        y={175.5}
        width={128.664}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <rect
        x={327.509}
        y={187.2}
        width={128.664}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <ellipse
        opacity={0.7}
        cx={272.924}
        cy={93.6}
        rx={13.646}
        ry={13.65}
        fill="#20BB96"
      />
      <path
        opacity={0.7}
        fillRule="evenodd"
        clipRule="evenodd"
        d="M407.437 132.6l-41.913-37.05-32.981 29.153-14.781-13.553-23.394 21.45h113.069z"
        fill="#20BB96"
      />
      <ellipse
        opacity={0.7}
        cx={345.054}
        cy={228.15}
        rx={13.646}
        ry={13.65}
        fill="#20BB96"
      />
      <rect
        x={366.498}
        y={214.5}
        width={35.09}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <rect
        x={366.498}
        y={226.2}
        width={128.664}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <rect
        x={366.498}
        y={237.9}
        width={128.664}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <ellipse
        opacity={0.7}
        cx={384.043}
        cy={278.85}
        rx={13.646}
        ry={13.65}
        fill="#20BB96"
      />
      <rect
        x={405.487}
        y={265.2}
        width={35.09}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <rect
        x={405.487}
        y={276.9}
        width={128.664}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <rect
        x={405.487}
        y={288.6}
        width={128.664}
        height={1.949}
        rx={0.975}
        fill="#fff"
      />
      <path
        d="M84.451 390c42.366 0 76.711-3.547 76.711-7.922s-34.345-7.922-76.711-7.922-76.71 3.547-76.71 7.922S42.084 390 84.45 390z"
        fill="#CCF1E8"
      />
      <path
        d="M89.464 295.811l.678.791a19.823 19.823 0 002.655-3.869c1.214-2.317 4.575-15.65 4.575-15.65s-10.674 6.553-10.985 8.7c-.311 2.148 3.077 10.028 3.077 10.028z"
        fill="#C69254"
      />
      <path
        d="M126.453 370.451c-2.22.229-4.497.419-6.484.505-5.071.215-9.486-6.897-11.312-10.309.085-.056.175-.104.269-.143 0 0 1.018-2.883-.339-9.323-.899-4.268-6.092-17.11-7.88-30.612.979-3.147 1.785-5.878 2.236-7.741 1.885-7.737 6.484-22.47 6.407-27.383-.07-4.501-5.391-8.095-6.281-8.663 1.735.538 8.299 2.811 9.288 6.811 1.145 4.62 5.804 67.752 5.804 67.752s4.586 14.447 8.292 19.106z"
        fill="url(#mobile-development-svg-prefix__paint2_linear)"
      />
      <path
        d="M109.345 285.449c.073 4.909-4.522 19.642-6.407 27.384-.451 1.863-1.257 4.594-2.236 7.741-3.089 9.937-7.907 24.027-8.813 28.019-1.188 5.258 2.778 22.421 2.778 22.421l.387 1.069c-2.273.032-4.803-.064-7.057-.446-4.304-.732-8.153-9.635-9.982-14.584.21-.257.468-.469.76-.623 1.413-.671 2.678-4.684 3.494-13.393.817-8.709 5.028-41.29 10.4-49.772l-5.12.744s-.093-4.51 1.932-9.057c1.029-2.307 2.602-4.624 5.01-6.365l.534-.369a14.977 14.977 0 014.676-1.997l2.933.44s.153.04.422.12c.898.573 6.22 4.168 6.289 8.668z"
        fill="#FDC4AF"
      />
      <path
        d="M44.624 311.81v24.31h8.009v-21.879c-2.806-2.13-5.766-2.544-8.009-2.431z"
        fill="#86C9B9"
      />
      <path
        d="M19.451 381.185c3.421 0 6.195-3.065 6.195-6.847 0-3.781-2.774-6.847-6.195-6.847-3.42 0-6.194 3.066-6.194 6.847 0 3.782 2.773 6.847 6.194 6.847zM55.205 380.49c0 3.782-2.774 6.846-6.195 6.846-3.42 0-6.194-3.065-6.194-6.846s2.773-6.846 6.194-6.846c3.42 0 6.195 3.064 6.195 6.846zM84.451 374.338c0 3.782-2.773 6.846-6.195 6.846s-6.194-3.065-6.194-6.846 2.773-6.846 6.194-6.846c3.42 0 6.195 3.064 6.195 6.846z"
        fill="#024132"
      />
      <path
        d="M43.156 336s5.403-2.205 11.146 0l.156 7.743 28.126 23.208v5.513H74.28l-.112-4.239-21.536-16.835.133 21.074-1.067 4.95h-5.606l-1.035-4.901-.233-20.538L23.4 368.053l-.09 4.116-7.696.05v-5.833L43.2 343.842v-4.645L43.157 336z"
        fill="#13B6A1"
      />
      <path
        d="M40.321 165.27s5.93-3.363 2.417-10.449-14.156-12.418-27.311-4.98C2.272 157.279 5.37 172.11 6.004 180.447c1.062 14.006-2.78 21.331-2.78 21.331s32.465 3.343 44.687-10.704c0-.003-8.555-12.895-7.59-25.804z"
        fill="#024132"
      />
      <path
        d="M102.257 235.818s10.953-2.373 15.02-1.244c4.067 1.129 13.448 5.181 11.07 7.149-2.377 1.969-11.296-.369-14.571-.707-3.276-.339-7.675 1.921-11.519-.113-3.843-2.035 0-5.085 0-5.085z"
        fill="url(#mobile-development-svg-prefix__paint3_linear)"
      />
      <path
        d="M59.029 237.74c-8.86-2.712-13.959-21.804-13.959-21.804l-12.199-22.037h-.056v4.013s12.323 39.091 22.733 43.019c20.892 7.141 49.719 1.681 49.719 1.681.52-.904.857-1.903.99-2.938-16.63 2.61-39.007.582-47.229-1.934z"
        fill="#FE8E00"
      />
      <path
        d="M45.07 215.942s5.099 19.096 13.959 21.803c8.22 2.522 30.597 4.545 47.228 1.936a8.564 8.564 0 00-.99-5.1s-29.81 1.865-42.688-5.254c-9.752-9.83-26.502-40.944-28.11-41.749-1.608-.805-1.654.226-1.654.226v6.101h.056l12.2 22.037z"
        fill="#FE8E00"
      />
      <path
        d="M91.3 223.051l9.192-6.822s2.075-2.585 7.117-1.78c5.042.805 7.073 1.314 10.123 3.347 3.05 2.034 1.398 4.365-5.93 4.279-7.328-.085-9.616.255-12.623 2.543-3.008 2.288-4.786 4.068-5.803 2.585-1.017-1.484-2.076-4.152-2.076-4.152z"
        fill="url(#mobile-development-svg-prefix__paint4_linear)"
      />
      <path
        d="M6.608 265.304v14.802s-.904 9.548 3.389 14.35c3.896 4.36 12.82 7.601 14.432 8.167l.253.086 2.09-4.632c.585-.12-5.744-1.704-11.973-7.119-2.063-1.791-2.317-6.605-2.373-10.395-.056-3.789 0-15.706 0-15.706l-5.897-.451.08.898z"
        fill="#86C9B9"
      />
      <path
        d="M100.021 385.69l-15.174-4.365s-1.98-.448-2.428-5.159c-.448-4.71-2.58-3.919-2.48-.643.1 3.275-.297 5.258-.297 5.258l-3.122-.647s1.612-5.332 0-12.029c-1.347-5.59.143-9.523 1.496-11.057 1.829 4.95 5.678 13.852 9.981 14.585 2.257.381 4.785.48 7.058.445l4.966 13.612z"
        fill="#FFAB00"
      />
      <path
        d="M162.007 203.336l-3.237-.917a.267.267 0 00-.329.184l-11.765 41.561a.267.267 0 00.184.329l3.237.917a.267.267 0 00.329-.184l11.765-41.56a.267.267 0 00-.184-.33z"
        fill="#86C9B9"
      />
      <path
        d="M147.003 244.356h-46.258a.197.197 0 00-.197.197v3.9c0 .108.088.197.197.197h46.258a.197.197 0 00.197-.197v-3.9a.197.197 0 00-.197-.197zM164.954 195.561l1.767-3.062-34.335-19.829-1.767 3.063 34.335 19.828zM168.194 241.946l3.533-.112-1.473-46.381-3.533.112 1.473 46.381z"
        fill="#86C9B9"
      />
      <path
        d="M127.653 185.315a6.364 6.364 0 01-11.995-4.212c.024-.072.049-.145.076-.217l11.919 4.429z"
        fill="#C6F4F3"
      />
      <path
        d="M167.631 200.708a5.254 5.254 0 005.253-5.255 5.254 5.254 0 00-5.253-5.255 5.254 5.254 0 00-5.253 5.255 5.254 5.254 0 005.253 5.255z"
        fill="#13B6A1"
      />
      <path
        d="M167.632 197.911a2.458 2.458 0 10-.003-4.915 2.458 2.458 0 00.003 4.915z"
        fill="#138979"
      />
      <path
        d="M180.019 241.723h-19.736a.496.496 0 00-.496.496v5.703c0 .274.222.496.496.496h19.736a.496.496 0 00.496-.496v-5.703a.496.496 0 00-.496-.496zM138.684 188.69c-.083.253-.169.501-.261.744l-31.447-11.686a16.77 16.77 0 0130.77-1.726 16.776 16.776 0 01.938 12.668zM75.782 297.794H20.995c-.615 0-1.114.499-1.114 1.114v14.889c0 .616.499 1.115 1.114 1.115h54.787c.616 0 1.115-.499 1.115-1.115v-14.889c0-.615-.5-1.114-1.115-1.114z"
        fill="#13B6A1"
      />
      <path
        d="M135.271 382.078l-15.928-.697s-2.372-.06-3.39-3.073c-1.019-3.014-3.445-5.39-3.789-1.235-.343 4.156-.677 5.005-.677 5.005l-3.586-.697s1.691-3.462 1.015-7.614c-.656-4.039-3.324-12.091-.269-13.924 1.826 3.412 6.244 10.525 11.312 10.31 1.987-.087 4.264-.276 6.484-.505.059.076.12.149.179.219 3.734 4.408 8.649 12.211 8.649 12.211z"
        fill="#FFAB00"
      />
      <path
        d="M28.355 265.082l.854-7.512-10.387-1.188s-4 18.746-3.685 26.012c.316 7.266 11.037 15.4 11.354 15.4h35.301l28.352-1.187c-.1-3.483.305-6.365 1.024-8.744-67.856 5.057-62.813-22.781-62.813-22.781z"
        fill="#024132"
      />
      <path
        d="M91.166 287.862c3.05-10.089 11.773-11.155 11.773-11.155-19.581-12.743-56.114-17.168-56.114-17.168l-2.21-.211-15.408-1.758-.854 7.512s-5.041 27.838 62.813 22.78z"
        fill="#024132"
      />
      <path
        d="M46.065 251.888c-.856-3.388-1.511-7.827-1.332-13.245.451-13.672.86-12.161.86-12.161s8.218-4.264 5.164-13.6c-1.607-4.925-5.37-6.464-9.31-12.611-3.939-6.147-4.442-13.028-9.061-13.494-4.62-.466-9.544-.318-9.544-.318l-.758.868s-7.404 6.907-7.855 18.207c-.281 7.024-.431 11.472-.505 15.107 1.014 2.838 14.547 39.788 32.341 31.247z"
        fill="#FFAB00"
      />
      <path
        d="M13.728 220.645c-.044 2.212-.06 4.126-.06 6.134 0 5.311 4.21 36.947 4.21 36.947s15.783 9.718 31.259-3.954c0 0-1.77-2.722-3.073-7.884-17.794 8.541-31.327-28.409-32.336-31.243z"
        fill="#FFAB00"
      />
      <path
        d="M30.3 186.437c-.488-4.186.615-10.254.615-10.254l-3.55-3.778-5.755 1.141c.369 2.644.584 5.307.643 7.976 1.394.322 4.815 1.427 8.047 4.915z"
        fill="url(#mobile-development-svg-prefix__paint5_linear)"
      />
      <path
        d="M22.254 181.522c.092 5.293-.65 7.729-.65 7.729 5.688 3.524 9.582.149 9.582.149-.472-.703-.745-1.755-.885-2.963-3.233-3.488-6.653-4.593-8.047-4.915z"
        fill="url(#mobile-development-svg-prefix__paint6_linear)"
      />
      <path
        d="M39.442 161.666s4.811 27.71-4.605 24.219c-9.416-3.49-9.14-7.635-9.14-7.635s-3.241.529-3.87-3.402c-.627-3.931 1.14-5.377 3.328-5.02 0 0 .611 1.47 3.676-2.46 0 0 2.694-5.051 5.284-5.949a4.913 4.913 0 005.327.247z"
        fill="#FDC4AF"
      />
      <path d="M130.926 254.723h-5.484v126.965h5.484V254.723z" fill="#024132" />
      <path
        d="M150.109 378.004h-44.365a.692.692 0 00-.692.692v3.204c0 .382.31.692.692.692h44.365c.382 0 .691-.31.691-.692v-3.204a.691.691 0 00-.691-.692zM192.142 248.414H69.711a.567.567 0 00-.567.567v7.123c0 .313.254.567.567.567h122.431a.567.567 0 00.567-.567v-7.123a.567.567 0 00-.567-.567z"
        fill="#024132"
      />
      <path
        d="M54.193 249.321c4.543.077 36.13-20.084 41.84-23.753-1.628-3.603-4.253-3.987-4.253-3.987s-30.838 15.592-35.977 14.178c-5.139-1.415-8.868-13.898-16.267-26.497-7.399-12.599-12.764-13.276-12.764-13.276s-3.219-.508-7.06 3.56a5.05 5.05 0 00-.96 1.482c3.794 5.785 17.115 26.105 19.436 29.731 2.703 4.239 11.006 18.476 16.005 18.562z"
        fill="#FFAB00"
      />
      <path
        d="M54.192 249.321c-4.998-.085-13.301-14.322-16.011-18.56-2.321-3.626-15.642-23.947-19.432-29.73-1.87 4.315 1.92 10.89 1.92 10.89s19.147 27.344 25.36 35.535c6.212 8.191 8.302 7.288 12.99 4.916 4.688-2.372 37.896-23.955 37.896-23.955a13.111 13.111 0 00-.888-2.849c-5.706 3.669-37.295 23.83-41.835 23.753z"
        fill="#FE8E00"
      />
      <path
        d="M16.661 222.485H1.073c-.592 0-1.073.481-1.073 1.074v41.127c0 .593.48 1.074 1.073 1.074h15.588c.593 0 1.073-.481 1.073-1.074v-41.127c0-.593-.48-1.074-1.073-1.074z"
        fill="#13B6A1"
      />
      <defs>
        <linearGradient
          id="mobile-development-svg-prefix__paint0_linear"
          x1={315.812}
          y1={27.3}
          x2={315.812}
          y2={351}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#62E4C5" />
          <stop offset={1} stopColor="#C0EDE2" />
        </linearGradient>
        <linearGradient
          id="mobile-development-svg-prefix__paint1_linear"
          x1={391.841}
          y1={64.35}
          x2={391.832}
          y2={300.3}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#27D6AC" />
          <stop offset={1} stopColor="#63EACA" />
        </linearGradient>
        <linearGradient
          id="mobile-development-svg-prefix__paint2_linear"
          x1={123.254}
          y1={380.292}
          x2={100.877}
          y2={278.265}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FDC4AF" />
          <stop offset={1} stopColor="#F9AD95" />
        </linearGradient>
        <linearGradient
          id="mobile-development-svg-prefix__paint3_linear"
          x1={129.866}
          y1={238.239}
          x2={94.369}
          y2={238.748}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#FDC4AF" />
          <stop offset={1} stopColor="#F9AD95" />
        </linearGradient>
        <linearGradient
          id="mobile-development-svg-prefix__paint4_linear"
          x1={77786}
          y1={36776}
          x2={69547.8}
          y2={37020.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#FDC4AF" />
          <stop offset={1} stopColor="#F9AD95" />
        </linearGradient>
        <linearGradient
          id="mobile-development-svg-prefix__paint5_linear"
          x1={25.724}
          y1={192.712}
          x2={26.318}
          y2={173.051}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#FDC4AF" />
          <stop offset={0.47} stopColor="#FAB39C" />
          <stop offset={0.8} stopColor="#F9AD95" />
        </linearGradient>
        <linearGradient
          id="mobile-development-svg-prefix__paint6_linear"
          x1={18474.1}
          y1={23384.4}
          x2={18519}
          y2={21856.4}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.01} stopColor="#FDC4AF" />
          <stop offset={1} stopColor="#F9AD95" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
