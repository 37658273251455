import * as React from "react"

function SvgComponent(props) {
  return (
    <svg
      width={540}
      height={390}
      viewBox="0 0 540 390"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse cx={58.54} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={58.54} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={58.54} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={58.54} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={58.54} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={58.54} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={58.54} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={81.265} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={103.99} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={126.715} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={172.165} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={17.606} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={63.069} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={40.337} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={85.8} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={131.263} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={149.44} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={108.531} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <ellipse cx={194.89} cy={153.994} rx={2.005} ry={2.006} fill="#FA4E4E" />
      <path
        d="M455.106 54.056H175.727a11.439 11.439 0 00-10.563 7.053 11.45 11.45 0 00-.871 4.372v183.404a11.417 11.417 0 003.35 8.079 11.435 11.435 0 008.084 3.346h279.379a11.439 11.439 0 008.084-3.346 11.412 11.412 0 003.35-8.079V65.486a11.42 11.42 0 00-7.057-10.56 11.443 11.443 0 00-4.377-.87z"
        fill="#FFE3E3"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M287.942 81.858h-99.419a2.72 2.72 0 00-2.721 2.72v1.207a2.72 2.72 0 002.721 2.72h99.419a2.72 2.72 0 002.721-2.72v-1.208a2.72 2.72 0 00-2.721-2.72zm0 14.426h-99.419a2.72 2.72 0 00-2.721 2.72v1.208a2.721 2.721 0 002.721 2.72h99.419a2.72 2.72 0 002.721-2.72v-1.208a2.72 2.72 0 00-2.721-2.72zm-99.419 14.429h99.419a2.72 2.72 0 012.721 2.72v1.208a2.72 2.72 0 01-2.721 2.719h-99.419a2.72 2.72 0 01-2.721-2.719v-1.208a2.721 2.721 0 012.721-2.72zm99.419 14.429h-99.419a2.72 2.72 0 00-2.721 2.719v1.208a2.721 2.721 0 002.721 2.72h99.419a2.72 2.72 0 002.721-2.72v-1.208a2.72 2.72 0 00-2.721-2.719zm-99.419 14.428h99.419a2.72 2.72 0 012.721 2.72v1.208a2.72 2.72 0 01-2.721 2.72h-99.419a2.721 2.721 0 01-2.721-2.72v-1.208a2.721 2.721 0 012.721-2.72z"
        fill="url(#consulting-svg-prefix__paint0_linear)"
      />
      <path
        d="M290.029 163.269h150.77a2.992 2.992 0 012.993 2.991v60.921a2.993 2.993 0 01-2.995 2.993H290.03a2.994 2.994 0 01-2.995-2.993V166.26a2.992 2.992 0 012.994-2.991z"
        fill="url(#consulting-svg-prefix__paint1_linear)"
      />
      <path
        opacity={0.5}
        d="M439.591 199.091v25.429a2.048 2.048 0 01-.605 1.453 2.064 2.064 0 01-1.456.599H292.726a2.069 2.069 0 01-1.457-.599 2.07 2.07 0 01-.605-1.453v-55.858c13.373 0 20.033 4.997 23.938 11.561.254.421.492.848.711 1.287 6.604 12.492 4.621 29.615 19.14 29.615a5.952 5.952 0 001.532-.192c.306-.065.604-.163.889-.291 9.775-4.113 8.245-33.298 25.624-33.09 3.713.046 6.486 1.469 8.675 3.73.21.229.42.455.621.711 7.781 9.127 7.891 29.468 19.84 33.161 4.307 1.331 8.112-.474 11.87-3.455.236-.192.483-.382.72-.592 7.946-6.592 15.847-17.862 28.107-15.1.355.082.711.178 1.075.276 2.178.655 4.259 1.6 6.185 2.808z"
        fill="#F24040"
      />
      <path
        d="M265.158 188.852c-3.994-17.678-19.791-30.884-38.684-30.884v39.649l38.684-8.765z"
        fill="#F96363"
      />
      <path
        d="M226.474 197.617v-39.649c-21.908 0-39.667 17.752-39.667 39.649a39.55 39.55 0 0011.593 28.059 39.587 39.587 0 0028.072 11.588c21.91 0 39.671-17.75 39.671-39.647a39.769 39.769 0 00-.985-8.765l-38.684 8.765z"
        fill="url(#consulting-svg-prefix__paint2_linear)"
      />
      <path
        d="M302.449 76.724H439.85a3.52 3.52 0 013.521 3.52v66.189a3.516 3.516 0 01-3.519 3.517H302.449a3.514 3.514 0 01-2.488-1.03 3.517 3.517 0 01-1.031-2.487V80.245a3.518 3.518 0 013.519-3.521z"
        fill="url(#consulting-svg-prefix__paint3_linear)"
      />
      <path
        d="M309.372 131.744c-.295-10.899 2.299-33.113 15.034-34.782 15.919-2.087 5.307 22.26 18.572 25.043 13.266 2.782 5.307-16.696 15.919-18.087 10.613-1.391 4.422 22.261 15.035 23.652 10.612 1.391 4.421-38.956 20.34-38.956S404 138.7 419.919 138.7c15.919 0 0-34.782 11.497-34.782"
        stroke="#fff"
        strokeWidth={2}
        strokeLinecap="round"
      />
      <path
        d="M449.781 55.746l-.653 1.847-133.7-55.477-133.703 55.477-.65-1.847L313.871.645 315.428 0l1.555.645 132.798 55.101zM186.806 371.666c25.889 0 46.877-5.243 46.877-11.71 0-6.468-20.988-11.711-46.877-11.711-25.889 0-46.876 5.243-46.876 11.711 0 6.467 20.987 11.71 46.876 11.71z"
        fill="#FFE3E3"
      />
      <path
        d="M168.062 358.606s-1.65 3.455-3.77 5.111c-10.579-5.461-20.01-13.404-23.523-16.522.289-.828.808-1.714 1.505-2.664 1.303-1.777 4.962-6.959 7.465-10.519.745 1.929 2.87 5.221 8.94 7.639-2.433 4.53 3.571 9.806 6.932 12.435 3.437 2.683 2.451 4.52 2.451 4.52z"
        fill="#5C1A4E"
      />
      <path
        d="M162.026 336.472s-.198.23-3.141 4.834a4.047 4.047 0 00-.201.347c-6.07-2.416-8.195-5.708-8.94-7.639 1.448-2.061 2.515-3.574 2.515-3.574l9.767 6.032z"
        fill="#FEA587"
      />
      <path
        d="M164.292 363.717a3.987 3.987 0 01-1.622.811c-2.844.573-18.345-11.014-20.643-12.986-1.519-1.3-1.822-2.718-1.258-4.341 3.514 3.112 12.944 11.055 23.523 16.516z"
        fill="#2F0000"
      />
      <path
        d="M231.208 356.213s.177 3.832-.914 6.296c-11.931.177-24.025-2.386-28.601-3.475a10.693 10.693 0 01.069-3.068c.309-2.187 1.093-8.495 1.623-12.826 1.571 1.353 5.007 3.259 11.512 2.524 0 5.152 7.795 6.978 12.002 7.71 4.312.751 4.309 2.839 4.309 2.839z"
        fill="#5C1A4E"
      />
      <path
        d="M215.4 339.507s-.066.296-.489 5.756c-.009.135-.016.27-.014.401-6.506.735-9.941-1.171-11.512-2.524.305-2.505.533-4.346.533-4.346l11.482.713z"
        fill="#FEA587"
      />
      <path
        d="M230.294 362.509a3.991 3.991 0 01-1.05 1.485c-2.234 1.845-21.421-1.066-24.384-1.718-1.955-.432-2.897-1.542-3.167-3.242 4.577 1.089 16.67 3.65 28.601 3.475z"
        fill="#2F0000"
      />
      <path
        d="M201.83 227.31s-1.955 26.032-4.457 38.354c-2.503 12.321-2.702 23.051-12.045 38.75-9.344 15.699-24.251 34.38-24.251 34.38s-9.543-1.788-8.945-9.538c0 0 17.693-26.629 19.682-50.476 1.989-23.848 3.182-48.887 3.182-48.887l26.834-2.583z"
        fill="#FFAB00"
      />
      <path
        d="M213.659 223.998l1.589.728s5.535 31.398 7.126 44.647c1.591 13.249-1.458 41.799-3.446 53.524-1.989 11.724-3.377 19.276-3.377 19.276s-8.681 1.807-13.937-3.075c0 0 3.797-45.215.219-58.331-3.578-13.115-4.458-18.878-4.458-18.878l1.077-37.891h15.207z"
        fill="#FE8E00"
      />
      <path
        d="M228.87 164.667s6.276 4.724 7.998 3.225c1.857-1.62 12.975-21.201 12.975-21.201s.864-4.78 2.515-7.486c1.521-2.487 4.891-6.466 7.918-3.043.898 1.014 3.779 6.998 1.543 9.085-2.531 2.361-1.527 1.034-5.688 3.716-1.699 1.098-11.355 30.151-19.672 31.027-3.594.378-12.843-6.614-12.843-6.614l5.254-8.709z"
        fill="#FFACA6"
      />
      <path
        d="M228.567 178.765s6.61-1.687 6.413-13.066c-.197-11.378-7.854-2.881-7.854-2.881l-5.121 9.593 6.562 6.354z"
        fill="#EA756C"
      />
      <path
        d="M287.783 96.723l-1.703-1.177-33.748 48.804 1.704 1.177 33.747-48.804z"
        fill="#1E2889"
      />
      <path
        d="M177.996 184.104c5.766-7.548 4.923-18.589 4.36-22.686.391 5.871-3.242 18.198-3.242 18.198s-.105.382-.288 1.089c-.199.771-.489 1.932-.83 3.399z"
        fill="#2F0000"
      />
      <path
        d="M182.355 161.419a7.285 7.285 0 00-.263-1.6s.122.581.263 1.6z"
        fill="#0054FC"
      />
      <path
        d="M212.65 158.053c2.784 6.857 1.904 12.72 1.904 12.72l1.226.789c.178-1.406.342-3.02.398-4.516.15-3.875-3.528-8.993-3.528-8.993z"
        fill="#2F0000"
      />
      <path
        d="M229.068 161.73s-8.373-7.77-11.375-12.154c-3.677-5.365-14.617-6.475-14.617-6.475l-10.388.049-1.806-.282s-14.799.936-19.173 11.568c-4.374 10.632-6.162 20.808-6.162 20.808s.292.329.825.814c1.527 1.394 5.056 4.086 9.495 4.066a10.96 10.96 0 003.25-.508s3.633-12.327 3.242-18.198c-.14-1.018-.263-1.599-.263-1.599.147.522.235 1.058.263 1.599.564 4.096 1.406 15.137-4.36 22.685a182.822 182.822 0 00-3.394 19c-.589 4.857-.967 10.005-.889 14.963.059 4.175.435 8.213 1.28 11.828 0 0 .997.794 3.073 1.581 1.825.693 4.489 1.379 8.044 1.517 6.559.256 16.153-1.357 29.149-8.264 0 0-3.377-13.713-1.888-31.001 1.49-17.289 1.193-22.952 1.193-22.952s.88-5.863-1.904-12.72c0 0 3.678 5.118 3.528 8.992-.056 1.503-.22 3.111-.398 4.516l7.124 4.577s7.344-7.256 6.151-14.41z"
        fill="#5C1A4E"
      />
      <path
        d="M173.389 187.98a809.634 809.634 0 012.471-7.858c-4.443.018-7.966-2.664-9.495-4.064a197.635 197.635 0 00-1.612 4.759c2.864 1.011 7.001 3.118 8.636 7.163z"
        fill="#EA756C"
      />
      <path
        d="M182.35 220.77s-4.632-9.659-7.747-17.669c-1.778-4.581-3.852-6.161-3.493-7.548.292-1.125 1.634-5.475 2.279-7.573-1.635-4.045-5.773-6.152-8.636-7.163-1.563 4.827-3.911 12.906-3.722 15.441.261 3.414 8.302 14.132 12.676 21.805a448.247 448.247 0 003.254 5.626c.53 2.568.897 5.168 1.099 7.782.074 1.089.112 2.196.081 3.25-.14 4.944 6.12 4.205 7.465.566a8.515 8.515 0 00.489-2.297c.286-3.432-1.093-7.795-3.745-12.22z"
        fill="#FFACA6"
      />
      <path
        d="M202.204 138.372a25.79 25.79 0 01.023-5.555l-5.119-6.659-6.413 2.583a25.389 25.389 0 011.2 8.705c3.455-.67 7.029-.35 10.309.926z"
        fill="#EA756C"
      />
      <path
        d="M210.529 112.844s6.361-3.676 6.112-10.126c-.249-6.45-3.229-11.277-9.443-10.78-6.214.498-6.189-2.931-9.742-4.002-3.512-1.066-10.842.804-11.135 5.972-.293 5.168-7.158 2.31-9.344 5.63-2.186 3.32-2.883 7.784-1.391 11.064 1.491 3.279-.83 4.569-.731 8.349.1 3.781 4.955 8.611 9.726 7.767 4.77-.844 3.132 4.521 10.042 3.626s15.906-17.5 15.906-17.5z"
        fill="#5C1A4E"
      />
      <path
        d="M211.473 109.127s3.132 16.594-.448 23.201c-3.579 6.607-12.177 1.739-15.059-4.72 0 0-3.58 1.142-4.822-4.173-1.243-5.315 3.976-4.263 4.423-2.732 0 0-2.188-5.315.356-6.36 2.543-1.045 15.202-1.151 15.55-5.216zM191.894 137.444a18.34 18.34 0 01-1.016 5.424s3.942 5.846 12.194.233a26.745 26.745 0 01-.869-4.729 18.644 18.644 0 00-10.309-.928z"
        fill="#FFACA6"
      />
      <path
        d="M434.14 372.535c17.929 0 32.462-5.037 32.462-11.25 0-6.214-14.533-11.251-32.462-11.251-17.928 0-32.462 5.037-32.462 11.251 0 6.213 14.534 11.25 32.462 11.25z"
        fill="#FFE3E3"
      />
      <path
        d="M489.941 381.844c17.928 0 32.462-5.037 32.462-11.25 0-6.214-14.534-11.251-32.462-11.251-17.928 0-32.462 5.037-32.462 11.251 0 6.213 14.534 11.25 32.462 11.25z"
        fill="#FFE3E3"
      />
      <path
        d="M488.917 242.087s-28.985-5.841-30.926-7.744c-1.941-1.903-8.288-7.628-14.681-5.294-6.394 2.334-6.484 5.862 1.075 7.479s6.169 3.908 12.255 3.434c0 0 16.204 9.312 33.292 12.59l-1.015-10.465z"
        fill="#EA756C"
      />
      <path
        d="M476.424 182.851s-9.341-6.025-9.938-13.623c-.597-7.598 5.322-11.94 9.015-11.887 0 0 4.072-12.592 16.834-9.605 12.761 2.986 17.05 17.367 17.595 22.577.546 5.21 7.764 19.431 3.15 23.338-4.614 3.906-14.119 8.685-14.119 8.685s-18.083 3.69-21.775.977c0 0 4.996-5.374 3.53-12.435-1.466-7.062-4.292-8.027-4.292-8.027z"
        fill="#5C1A4E"
      />
      <path
        d="M495.556 195.313l2.033.509s3.16-.681 7.882 6.974c4.723 7.655 11.185 26.169 8.425 51.291 0 0-11.403 6.395-24.407 0 0 0-.533-10.505-4.987-18.975s-2.712-18.562-1.985-20.305c.727-1.743 7.481-17.788 7.481-17.788l5.558-1.706z"
        fill="#2F0000"
      />
      <path
        d="M501.612 204.405a5.293 5.293 0 00-7.319 1.553l-20.989 32.28c-3.491.485-29.053 3.996-31.344 2.923-2.458-1.151-10.353-4.441-15.607-.11-5.254 4.331-4.161 7.687 3.505 6.695 7.666-.991 7.12 1.64 12.699-.837 0 0 16.656 3.076 33.099 1.137a5.284 5.284 0 005.399-2.32l22.111-34.003a5.272 5.272 0 00.739-3.981 5.287 5.287 0 00-2.293-3.337z"
        fill="#FFACA6"
      />
      <path
        d="M489.942 193.374c-.108-2.285-.462-5.088-1.468-6.751l5.945-7.687 1.101 2.233s.463 3.376 1.066 6.873c-1.749.494-4.417 1.825-6.644 5.332z"
        fill="#EA756C"
      />
      <path
        d="M496.584 188.037c.594 3.466 1.422 7.259 1.976 7.886 0 0-3.489 3.354-8.62 1.156 0 0 .1-1.641 0-3.706 2.229-3.506 4.897-4.837 6.644-5.336zM476.339 166.142s-3.694 21.222 4.18 24.858c7.874 3.637 13.631-7.653 13.631-8.63 0 0 4.079-.373 3.427-4.727-.653-4.354-4.032-2.688-4.032-2.688s-12.448-1.403-17.206-8.813z"
        fill="#FFACA6"
      />
      <path
        d="M457.315 351.061c-7.888 12.349-15.399 10.055-15.399 10.055l-.974-.829.067-.043s2.547-4.594 4.488-8.294c1.538-2.93-2.177-31.389-3.732-50.612-.409-5.047-.672-9.46-.649-12.613.057-8.849 2.599-12.063 4.701-13.205 1.491-.81 2.76-.579 2.76-.579l8.308 17.232-2.844 2.487c3.532 12.3 1.69 28.468.722 40.235-.969 11.768 1.267 14.374 1.267 14.374.483.557.913 1.157 1.285 1.792z"
        fill="#EA756C"
      />
      <path
        d="M457.315 351.063c-7.889 12.347-15.401 10.053-15.401 10.053l-.974-.831c-.711.407-6.473 3.83-7.127 7.301-.697 3.699 13.48 3.759 16.174.103l3.813-6.72 1.015 7.388 1.903-.042 1.459-9.139s1.621-3.869-.862-8.113zM417.584 348.597l-.066-.115c-.817.208-6.949 1.862-8.442 4.871-1.6 3.226 11.439 6.958 14.862 4.287l5.259-5.206-.981 7.067 1.763.455 3.715-8.038s2.311-2.889 1.449-7.142c-11.443 8.467-17.559 3.821-17.559 3.821z"
        fill="#5C1A4E"
      />
      <path
        d="M446.949 272.541l-.236-.688s-6.432-3.146-10.451 10.764-9.619 56.457-12.367 59.359c-2.748 2.903-6.283 6.477-6.283 6.477l-.094.022.064.119s6.117 4.649 17.555-3.818a10.647 10.647 0 00-.85-2.522s-1.384-2.981 2.563-13.569c3.146-8.437 8.329-20.011 10.046-30.744l1.98-8.856.638-3.908-2.565-12.636z"
        fill="#FFACA6"
      />
      <path
        d="M513.595 252.528l.301 1.558s1.27 7.211 3.217 13.782c2.323 7.845-1.319 20.087-11.431 22.285-10.111 2.197-49.114 2.13-49.114 2.13s-12.4-11.558-12.896-21.013c0 0 24.129-7.013 44.061-9.044l2.216-10.337 23.646.639z"
        fill="#FFAB00"
      />
      <path
        d="M491.881 305.045v37.203h-5.885v-35.53a9.277 9.277 0 015.885-1.673z"
        fill="#FFA8A8"
      />
      <path
        d="M510.372 373.182c2.513 0 4.55-2.105 4.55-4.701 0-2.596-2.037-4.7-4.55-4.7s-4.55 2.104-4.55 4.7 2.037 4.701 4.55 4.701zM484.107 372.704c0 2.596 2.039 4.701 4.55 4.701 2.511 0 4.552-2.105 4.552-4.701 0-2.595-2.039-4.7-4.552-4.7-2.513 0-4.55 2.105-4.55 4.7zM462.624 368.481c0 2.596 2.037 4.701 4.55 4.701s4.55-2.105 4.55-4.701c0-2.595-2.037-4.699-4.55-4.699s-4.55 2.104-4.55 4.699z"
        fill="#5C1A4E"
      />
      <path
        d="M492.96 342.166s-3.972-1.513-8.188 0l-.115 5.316-20.662 15.929v3.784h6.1l.081-2.91 15.819-11.547-.098 14.464.786 3.399h4.118l.759-3.365.178-14.096 15.738 11.035.066 2.825 5.654.034v-4.003l-20.262-15.475v-3.187l.026-2.203z"
        fill="#FA4E4E"
      />
      <path
        d="M533.088 255.033v15.474s.944 9.982-3.546 15c-4.077 4.559-13.412 7.946-15.107 8.527l-.263.089-2.187-4.843c-.613-.128 6.008-1.776 12.527-7.441 2.158-1.873 2.423-6.911 2.489-10.867.065-3.956 0-16.418 0-16.418l6.172-.466-.085.945z"
        fill="#FFA8A8"
      />
      <path
        d="M515.981 288.993H462.77a3.22 3.22 0 00-3.221 3.219v11.458a3.22 3.22 0 003.221 3.219h53.211a3.22 3.22 0 003.22-3.219v-11.458a3.22 3.22 0 00-3.22-3.219zM524.547 255.506h12.351a3.101 3.101 0 003.102-3.1v-39.04c0-1.712-1.389-3.1-3.102-3.1h-12.351a3.1 3.1 0 00-3.101 3.1v39.04a3.1 3.1 0 003.101 3.1z"
        fill="#FA4E4E"
      />
      <path
        d="M397.994 248.55l2.43-.688a.767.767 0 00.529-.948l-11.992-42.33a.767.767 0 00-.948-.529l-2.43.687a.767.767 0 00-.529.948l11.992 42.33c.115.408.54.645.948.53z"
        fill="#5C1A4E"
      />
      <path
        d="M401.121 252.045h46.231c.31 0 .56-.25.56-.559v-3.352a.56.56 0 00-.56-.56h-46.231a.56.56 0 00-.56.56v3.352a.56.56 0 00.56.559z"
        fill="#5C1A4E"
      />
      <path
        d="M69.161 390c29.989 0 54.299-6.073 54.299-13.565 0-7.492-24.31-13.565-54.299-13.565-29.988 0-54.298 6.073-54.298 13.565 0 7.492 24.31 13.565 54.298 13.565z"
        fill="#FFE3E3"
      />
      <path
        d="M140.879 204.575l-11.992 42.331a.766.766 0 00.53.947l2.43.688a.769.769 0 00.948-.529l11.992-42.331a.768.768 0 00-.53-.947l-2.43-.688a.769.769 0 00-.948.529z"
        fill="#5C1A4E"
      />
      <path
        d="M128.715 247.574H82.484a.56.56 0 00-.56.56v3.352c0 .309.25.559.56.559h46.231a.56.56 0 00.56-.559v-3.352a.56.56 0 00-.56-.56z"
        fill="#5C1A4E"
      />
      <path
        d="M93.877 353.191s.09 4.003-.245 7.186c-.334 3.184-2.757 5.489-1.955 8.584.801 3.095 9.875 4.113 16.268 5.537 6.393 1.425 12.264.622 11.924-4.318-.339-4.941-11.032-4.1-14.535-10.56-3.503-6.459-2.163-7.317-2.163-7.317l-9.294.888z"
        fill="#FE8E00"
      />
      <path
        d="M104.135 274.678s3.252 1.694 3.258 7.203c.005 5.509-1.63 72.88-1.63 72.88s-6.28 5.085-14.052-.71l-1.838-37.431 14.262-41.942z"
        fill="#421037"
      />
      <path
        d="M63.183 267.234s27.279.711 37.163 2.912c11.855 2.643 11.512 13.009 8.746 21.483-2.765 8.474-30.947 63.008-30.947 63.008s-5.919 3.189-13.455-3.969c0 0 21.714-50.851 20.019-54.431-1.696-3.58-40.196 1.052-46.13-6.004-1.05-1.244 2.422-6.229 2.422-6.229l22.182-16.77z"
        fill="#5C1A4E"
      />
      <path
        d="M123.227 239.876c-5.48-4.514-13.716-1.085-16.281.116-1.992.933-20.862-1.457-29.19-2.572l-22.394-34.434a5.736 5.736 0 00-7.936-1.682l-.356.231a5.727 5.727 0 00-2.515 5.854c.138.74.42 1.446.83 2.078l22.827 35.105c.394.607.9 1.134 1.491 1.552l-.417.931c17.951 2.702 37.033-1.066 37.033-1.066 5.821 2.582 5.252-.163 13.247.871 7.994 1.034 9.141-2.469 3.661-6.984z"
        fill="#EA756C"
      />
      <path
        d="M49.722 230.344s-6.79-11.629-9.568-18.225c-2.778-6.596-4.408-14.66-.96-17.602 3.448-2.941 9.305-2.737 12.442 1.573 3.137 4.309 14.782 22.895 14.782 22.895s-4.16 13.73-16.696 11.359z"
        fill="#FC8300"
      />
      <path
        d="M40.038 187.769l-1.507.158s-5.025.972-7.913 8.191c-2.888 7.22-6.594 22.787-6.909 30.2-.314 7.414-1.946 34.667 2.998 48.367 4.945 13.7 9.705 16.955 13.33 16.955 0 0 23.412.71 25.813-24.299 0 0-6.97-.91-8.666-6.873-1.696-5.964-3.13-17.794-3.2-23.846-.12-10.083 2.047-25.017-2.098-40.271-.393-2.31-2.455-3.804-2.455-3.804l-9.393-4.778z"
        fill="#FFAB00"
      />
      <path
        d="M119.887 244.584c-5.48-4.512-13.716-1.085-16.281.116-2.218 1.039-25.344-2.04-31.568-2.894l-22.181-34.109a5.75 5.75 0 00-3.62-2.484 5.735 5.735 0 00-4.316.802l-.356.231a5.738 5.738 0 00-2.486 3.617 5.727 5.727 0 00.803 4.315l22.827 35.107a5.72 5.72 0 003.279 2.4l-.037.083c17.951 2.702 37.033-1.066 37.033-1.066 5.821 2.582 5.252-.163 13.247.871 7.994 1.034 9.135-2.475 3.656-6.989z"
        fill="#FFACA6"
      />
      <path
        d="M46.375 235.052s-6.79-11.629-9.57-18.223c-2.779-6.595-4.405-14.662-.957-17.604 3.448-2.941 9.304-2.735 12.441 1.573 3.137 4.308 14.783 22.897 14.783 22.897s-4.156 13.73-16.697 11.357z"
        fill="#FE8E00"
      />
      <path
        d="M41.128 176.564l.416 2.857s-.69 5.523-3.021 8.506c0 0 .377 2.432 4.05 4.22 3.733 1.812 6.847.393 6.847.393s-.628-4.207 1.098-9.385c1.726-5.179 1.76-5.367 1.76-5.367l-6.626-7.658-4.524 6.434z"
        fill="#FFACA6"
      />
      <path
        d="M58.314 156.32l1.185-1.663s6.415-2.857 7.858-9.824c1.444-6.968-.753-9.416-.753-9.416s-5.715 5.211-13.377 4.834c-7.662-.376-16.958-3.139-24.556 7.22-7.598 10.359.878 21.028 2.826 23.664 1.948 2.637 10.048 8.288 10.048 8.288l6.155-9.238 10.614-13.865z"
        fill="#5C1A4E"
      />
      <path
        d="M59.35 154.06s3.199 12.24 2.355 19.68c-.844 7.44-9.547 10.2-16.37-1.35 0 0-4.145-.063-4.207-5.964 0-2.386 1.696-3.327 3.894-2.51 0 0 .502-2.951 3.077-3.077 2.575-.126 2.279-3.139 3.683-3.892 1.404-.754 5.778.252 7.568-2.887z"
        fill="#FFACA6"
      />
      <path
        d="M48.123 308.774v37.204h5.883v-35.529a9.291 9.291 0 00-5.883-1.675z"
        fill="#FFA8A8"
      />
      <path
        d="M29.627 376.913c2.512 0 4.55-2.105 4.55-4.701 0-2.596-2.038-4.701-4.55-4.701-2.514 0-4.55 2.105-4.55 4.701 0 2.596 2.036 4.701 4.55 4.701zM55.894 376.435c0 2.595-2.037 4.698-4.55 4.698-2.514 0-4.552-2.103-4.552-4.698 0-2.596 2.038-4.701 4.552-4.701 2.513 0 4.55 2.103 4.55 4.701zM77.378 372.212c0 2.595-2.037 4.699-4.552 4.699-2.515 0-4.55-2.104-4.55-4.699 0-2.595 2.038-4.701 4.55-4.701 2.511 0 4.552 2.104 4.552 4.701z"
        fill="#5C1A4E"
      />
      <path
        d="M47.042 345.897s3.97-1.514 8.189 0l.113 5.315 20.662 15.928v3.784h-6.1l-.08-2.908-15.818-11.547.097 14.464-.783 3.398h-4.118l-.761-3.364-.178-14.095-15.74 11.034-.064 2.826-5.656.034v-4.013l20.262-15.473v-3.189l-.025-2.194z"
        fill="#FA4E4E"
      />
      <path
        d="M6.913 258.762v15.475s-.945 9.98 3.555 15.001c4.076 4.556 13.412 7.946 15.099 8.527l.265.09 2.186-4.842c.611-.128-6.01-1.777-12.527-7.442-2.158-1.874-2.423-6.911-2.488-10.867-.066-3.956 0-16.42 0-16.42l-6.173-.465.083.943z"
        fill="#FFA8A8"
      />
      <path
        d="M77.232 292.723h-53.21a3.22 3.22 0 00-3.221 3.219v11.459a3.22 3.22 0 003.22 3.219h53.211a3.22 3.22 0 003.22-3.219v-11.459a3.22 3.22 0 00-3.22-3.219zM15.452 213.995H3.102a3.1 3.1 0 00-3.102 3.1v39.04a3.1 3.1 0 003.102 3.1h12.35a3.1 3.1 0 003.102-3.1v-39.04a3.1 3.1 0 00-3.102-3.1z"
        fill="#FA4E4E"
      />
      <path
        d="M75.232 355.325c-2.266.133-5.688-.466-9.598-3.809-.313.369-.63.73-.945 1.066-2.162 2.361-5.48 2.778-6.678 5.743-1.198 2.965 5.496 9.172 9.79 14.118 4.294 4.946 9.487 7.781 12.159 3.61 2.671-4.171-6.434-9.851-5.41-17.126.162-1.212.39-2.415.682-3.602z"
        fill="#FFAB00"
      />
      <path d="M461.37 252.552H95.094v4.375H461.37v-4.375z" fill="#421037" />
      <path
        d="M461.369 256.925H95.092v5.795H461.37v-5.795zM120.756 262.72h-4.562v120.149h4.562V262.72zM439.486 262.72h-4.563v120.149h4.563V262.72z"
        fill="#421037"
      />
      <defs>
        <linearGradient
          id="consulting-svg-prefix__paint0_linear"
          x1={238.233}
          y1={81.858}
          x2={238.233}
          y2={146.218}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7575" />
          <stop offset={1} stopColor="#FF9595" />
        </linearGradient>
        <linearGradient
          id="consulting-svg-prefix__paint1_linear"
          x1={365.414}
          y1={163.269}
          x2={365.414}
          y2={230.174}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7575" />
          <stop offset={1} stopColor="#FF9595" />
        </linearGradient>
        <linearGradient
          id="consulting-svg-prefix__paint2_linear"
          x1={226.475}
          y1={157.968}
          x2={226.475}
          y2={237.264}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7575" />
          <stop offset={1} stopColor="#FF9595" />
        </linearGradient>
        <linearGradient
          id="consulting-svg-prefix__paint3_linear"
          x1={371.151}
          y1={76.724}
          x2={371.151}
          y2={149.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FF7575" />
          <stop offset={1} stopColor="#FF9595" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default SvgComponent
